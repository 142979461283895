import React, {useState} from 'react';
//import intercalate from 'intercalate';
import Box from './Box';
import ButtonBox from './ButtonBox';
import WName from './pages/WName';
import NW from './pages/neologisms/NewWord';
import QL from './pages/faq/QuestionLink';
import PL from './pages/problems/ProblemLink';
import AL from './pages/angles/AngleLink';
import OML from './pages/ormaybe/OrMaybeLink';
import LRL from './pages/longreads/LongReadLink';
import {E1, E2, Qual, rand, shuffleSettingTop} from './pages/common';
import {Link} from 'react-router-dom';
import './Warning.css';

const disclaimer = <div id="disclaimer">
  <p>This site isn't ready and probably never will be, sorry.</p>
{/*  <p><QL name="pg.">TL;DR</QL>: <WName cap /> <OML name="ideas">grows</OML> <QL name="evolution">really</QL> (<OML name="offensive">bloody</OML>) <PL name="unready">fast</PL>; <LRL name="superpositions">if</LRL> <AL name="framework">it’s</AL> <OML name="gaia">not</OML> <QL name="unity.answer">as</QL> <LRL name="motto">colourful</LRL> <QL name="unity.1">as</QL> <AL name="fields.science">this</AL> ‘<QL name="pg">disclaimer</QL>’, <LRL name="notions">it’s</LRL> <i><NW word="conservatism">old</NW></i>. (<PL name="comprehensive">Even</PL> ‘<Link to="/menu/gist">The Gist</Link>’, <Qual.maybe />.)</p> */}
  <p><QL name="pg.">TL;DR</QL>: The <NW>POC</NW> inside <PL name="individuality">whom</PL> this <LRL>idea</LRL> is <AL name="body">trapped</AL> honestly <i><PL name="explainage">sucks</PL></i> at <NW>explainage</NW>.</p>
  <p>The way <AL name="fields.science">this thing</AL> is <QL name="evolution">growing</QL> means that it can never be <i>ready</i> for the <QL name="polygnosticity">eyes of ‘others’</QL> to <PL name="denial">tear it apart</PL>. Many thoughts are still <PL name="explainage">badly expressed</PL>, and much of the ‘work’ is re-writing past <E1 word="explanations" /> that were even worse. Unfortunately, time is a limited resource (<LRL name="gaiacentrism">on Earth</LRL>, at this ‘<NW>level</NW>’ of ‘<LRL name="i.">reality</LRL>’); the <AL name="framework">theory</AL> <LRL name="notions">itself</LRL> may ba <PL name="all.">time-invariant</PL>, but in order for it to get <i>off this <LRL name="gaia">rock</LRL></i> and ‘<NW>coalesce</NW>’ into <QL name="hivemind.mortality">immortality</QL> with any ‘<OML>aliens</OML>’ who might be out there it needs to deal with the <NW>individual greed</NW> and <NW>illusions</NW> of <QL>humanity</QL> first. So either you (as a human reader) forgive the outward shoddiness, or <LRL name="gaia">Earth</LRL> the <AL>system</AL> – as run by <AL name="handicap">humans</AL> – <PL name="sweeping.ig">drowns</PL> in its <QL name="body.">cells</QL>’ <NW>individual greed</NW>, and we just hope that <OML name="robots">someone else</OML> takes <OML name="takeover">the wheel</OML>.</p>
</div>;

// Turns out we don't even need to name the property it's replacing,
// but anyway...
const variableBoxItems = {
  preludeOptions: 'prelude',
  outsideOptions: 'outside'
}

// This is a COMPONENT:
const WarningBox = ({warning, num, showAll}) => {
  // Create an empty object:
  let wDisplay = {};

  // And then fill it with stuff from the provided warning:
  for (let key in warning) {
    if (!warning.hasOwnProperty(key)) {continue;}
    if (variableBoxItems[key]) {
      let options = warning[key];
      if (!options || !options.length) {continue;}
      // Pick one
      let optionNum = num % options.length;
      // 'Chosen' will be an array to potentially intercalate with <hr />'s:
      let chosen;
      if (!showAll) {
        chosen = [options[optionNum]];
      }
      else {
        chosen = shuffleSettingTop(options, optionNum);
      }

      for (let i=0;i<chosen.length;i++) {
        let selected = chosen[i];
        for (let oKey in selected) {
          if (!selected.hasOwnProperty(oKey)) {continue;}
          // If it already exists just append
          // (with an <hr /> separator):
          if (wDisplay[oKey] && wDisplay[oKey].push) {
            wDisplay[oKey].push(<hr />);
            wDisplay[oKey].push(selected[oKey]);
          }
          // Otherwise create it:
          else {
            wDisplay[oKey] = [selected[oKey]];
          }
        }
      }
      continue;
    }
    wDisplay[key] = warning[key];
  }
  return <Box {...wDisplay} />;
}

const warnings = [
  // Caught in the middle
  {
    name: "middle", // For debugging
    prelude: <p>
      Things always are so darn obvious in <i>retrospect</i>.
    </p>,
    header: <span>
      We are <AL name="handicap">caught in the middle</AL>, <PL name="invisible">unable to see</PL> the <LRL name="gaia">whole</LRL>.
    </span>,
    outside: <p>
      This is a <AL>framework</AL> to help <QL name="pg.mind">you</QL> <LRL>understand</LRL> how <PL name="sweeping">every</PL> <PL name="individuality">individual</PL> <LRL name="gaiacentrism">on the planet</LRL> is (<OML name="wrong">probably</OML>) <QL name="reductionist">just</QL> a <AL name="dysfunctional.">fragment</AL> of the <QL name="unity">same</QL> conscious ‘<QL>body</QL>’.
    </p>,
  },

  // Uncomfortable but it WORKS
  {
    name: "works", // For debugging
    prelude: <p>
      It’s going to seem <PL>uncomfortable</PL>.
    </p>,
    header: <span>
      It’s going to seem <PL name="unready">weird</PL>.
    </span>,
    outside: <p>
      But this is an <QL name="unity.1">answer</QL> to most of <QL name="anthropocentrism">humanity</QL>’s problems. It just <i><AL name="framework.">works</AL></i>.
    </p>,
  },

  // All about ENTROPY
  {
    name: "entropy", // For debugging
    prelude: <p>
      If this doesn’t make any sense, refresh the page to get another ‘warning’.
    </p>,
    header: <span>
      This is all about <i><NW>entropy</NW></i>.
    </span>,
    outside: <p>
      And if your <NW>POC</NW> hasn’t <QL name="pg.mind">figured that out</QL>, then you don’t (<LRL name="waiting">yet</LRL>) <i><LRL>understand</LRL></i> this <AL name="framework">thing</AL>.
    </p>,
  },

  // A 'heartless' systems analysis of Planet Earth
  {
    name: "systems", // For debugging
    prelude: <p>
      <b><PL name="uncomfortable">Warning</PL>:</b> this <QL>Weltanschauung</QL> (frankly, my dear) doesn’t give a <OML name="offensive">damn</OML> about your <PL>individuality</PL>, your <i><AL name="handicap">feelings</AL></i>, your individual ‘<OML>soul</OML>’…
    </p>,
    header: <span>
      This is a ‘<PL name="ick.rude">heartless</PL>’ <AL name="systems">systems analysis</AL> of <LRL name="gaia">Planet Earth</LRL>.
    </span>,
    outsideOptions: [
      {
        outside: <p>
          This ‘<NW word="copernicus">modern-day Copernicus<sup>†</sup></NW>’ is mostly just <LRL>waiting</LRL> for <QL>humanity</QL> to <AL name="sleeping">wake up</AL> and shrug off its <NW>illusions</NW> of <QL name="roadblocks.god">externality</QL> to see <QL name="unity.answer">the universe</QL> as <QL name="reductionist">just</QL> a <i><PL name="ick.">system</PL></i>… but <LRL name="gaia">Planet Earth</LRL> (<LRL name="gaiacentrism">at least</LRL>) is <QL name="urgent">running out of time</QL>.
        </p>,
        coda: <p>
          † To be an <NW word="atomocentrism">atomocentrist</NW>.
        </p>,
      },
      {
        outside: <p>
          <QL name="qualifiers">Probably</QL> it can be <PL name="all.scale">extended</PL> to <OML name="aliens">other planets</OML>… but only if the conscious <QL name="body.">super-organ</QL> of <LRL name="gaia">Earth</LRL> can fix <AL name="dysfunctional">itself</AL> <QL name="urgancy">first</QL>.
        </p>,
      },
    ],
  },

  // It's TOTALLY different
  {
    name: "different", // For debugging
    prelude: <p>
      You can be reading along fine… and then it’ll throw something at you that’s like <PL name="uncomfortable">a ‘bolt from the blue’</PL>.
    </p>,
    header: <span>
      This comes from a <i>completely</i> <LRL>different</LRL> foundation from what you (<QL name="qualifiers">most likely</QL>) know.
    </span>,
    outside: <p>You (as a <NW>heterognostic</NW> <QL name="hivemind">individual human reader</QL>) need to dismiss <i>all</i> of your <QL>anthropocentrism</QL>, <NW>atomocentrism</NW>, and <i><NW>conservative thinking</NW></i>… which is (admittedly) kind of hard to do when these <NW home>terms</NW> are so darn <i><PL name="comprehensive">vast</PL></i> that they encompass just about everything that you (as a <PL name="individuality">human individual</PL>) may have ever <QL name="polygnosticity">personally <i>known</i></QL>.</p>,
  },

];

export default function Warning(props) {
  // Start at a default num to randomly pick a 'warning'
  // and any sub-elemets within it;
  // this will never be CHANGED, but set in state so it persists
  // through re-renders (as our 'individuality' most likely does NOT).
  // Multiply by a (relatively) large prime number (101) and then just use it
  // with the modulus (%) operator to select elements in various arrays:
  const [num, setNum] = useState(rand(0, 999)*101);
  // For when we show ALL of them
  // (which state can only be changed once):
  const [showAll, setShowAll] = useState(false);
  let display = null;
  // The array index in the 'warnings' array:
  let warningNum = num % warnings.length;
  // The current element:
  let cur = warnings[warningNum];
  // If we are displaying all:
  if (showAll) {
    /* Shuffle it while keeping 'cur' on top. */
    // First make a copy:
    let warningsShuffle = shuffleSettingTop(warnings, warningNum);
    display = warningsShuffle;
  }
  // Else just one
  // (in an array so it can be displayed with 'map'):
  else {
    display = [cur];
  }
  return <div className='warning'>
    <div id='warning-div'>
      {(display) ? display.map(
        (warning) => <WarningBox
          warning={warning}
          num={num}
          showAll={showAll}
        />) : "Error choosing a ‘warning’."}
    </div>
    <h2 id="disclaimer-header">Disclaimer:</h2>
    {disclaimer}
    {!showAll && <ButtonBox onClick={()=>setShowAll(1)}>
      Show all ‘warnings’
    </ButtonBox>}<br />
    <ButtonBox src="gist">Give me the gist</ButtonBox>
  </div>;
}
