import React from 'react';
import {Link} from 'react-router-dom';
import Box from '../../Box';
import {wName, WEtymology} from '../../wName';
import WName, {WArticle} from '../WName';
import {EonEtymology} from '../../eName';
import Eon from '../Eon';
import NW from './NewWord';
import QL from '../faq/QuestionLink';
import PL from '../problems/ProblemLink';
import AL from '../angles/AngleLink';
import OML from '../ormaybe/OrMaybeLink';
import LRL from '../longreads/LongReadLink';
import {E1, E2, Qual, Planet} from '../common';

export const newWords = [
{
  word: "synecdocentrism",
  match: "synecdocentric",
  display: <WName noNW />,
  important: true,
  etymology: <span className="etymology"><WEtymology /></span>,
  shortdef: <span><OML name="matters">Focusing</OML> on <QL name="unity">our shared consciousness</QL> (and <QL>conscious</QL> <i><NW>agency</NW></i>) <LRL name="shift">rather than</LRL> on <QL>human</QL> (or <OML name="animals">animal</OML>, or <OML name="aliens">other non-human</OML>) <i><PL name="individuality">individuals</PL></i>.</span>,
  def: [
    <p><b>This is the big one</b>: not just <PL>hard</PL> to <LRL>understand</LRL> but <PL name="uncomfortable">hard to ‘stomach’</PL> for <Qual.probably /> <PL name="unready">many</PL>… but here’s <Link to="/menu/gist">the gist</Link>: <PL name="special">Your</PL> ‘<PL>individuality</PL>’ is <QL name="qualifiers">almost</QL> <OML name="levels">certainly</OML> <PL name="reductionist">just</PL> an <NW>illusion</NW>. <QL>Consciousness</QL> is (<OML name="wrong">presumably</OML>) <QL name="unity.1">one</QL>. As a <AL>sleeping</AL> but <QL name="body.super-organ">unified</QL> <AL name="fields.">biological</AL> ‘<LRL name="gaia">organism</LRL>’, <LRL name="gaiacentrism">Planet Earth</LRL> has a <NW>polygnostic</NW> <QL>consciousness</QL> – with (cf. ‘<QL>gnosticity</QL>’) <PL name="all.perspective">some</PL> <AL name="dysfunctional.">fragments</AL> (<OML name="soul">i.e.</OML>, ‘<AL name="self">you</AL>’) <AL name="handicap">stuck with</AL> a <QL name="polygnosticity">separate</QL> ‘<QL name="pg.">mind</QL>’ (and <LRL>understanding</LRL>) from <LRL name="them">others</LRL> (‘<AL name="self">I</AL>’). <PL name="all.time">Presently</PL> (not <NW word="conservatism">thinking conservatively</NW>) the <PL name="explainage">only way</PL> to <LRL name="communism">share information</LRL> through this <QL>conscious</QL> <NW>polygnosticity</NW> is <i><NW>explainage</NW></i>… but this <i><LRL name="shift">need not always be the case</LRL></i>.</p>,
    <p><QL name="whatcanido">Help us</QL> past the <QL>roadblocks</QL>:</p>,
    <ol>
      <li>The <AL name="handicap">limits</AL> of <AL name="fields.">biology</AL>, which can be <AL name="mirrors">overcome</AL>: <QL name="salvation">first</QL> <LRL name="imagination">imaginatively</LRL> and – <NW word="god">inshallah</NW> – then <QL name="evolution">physically</QL>; cf. <Eon Eon />.</li>
      <li>And <QL>humanity</QL>’s <PL name="uncomfortable">insistence</PL> on <i><PL>privacy</PL></i> (<PL name="denial">refusing to admit</PL> that <QL>consciousness</QL> is <PL name="sweeping">all</PL> a <QL name="unity.answer">single</QL>, if <OML name="kaput">deathly</OML> <AL>sick</AL>, ‘<LRL name="gaia">organism</LRL>’).</li>
    </ol>,
    <p>End <LRL name="gaia">Earth</LRL>’s <QL>polygnosticity</QL>… and <AL name="notnew.lennon.">peace</AL> will have a <OML name="harmony">chance</OML>.</p>,
  ],
},
{
  word: "atomocentrism",
  match: "atomocentric",
  important: false,
  etymology: <span className="etymology">Greek <i>άτομο</i> (‘AH-toe-mo’: an individual) + <i>κεντρικός</i> (‘ken-tree-COAS’: centred).</span>,
  shortdef: <span>Focusing on the <i><PL name="individuality">individual</PL></i>; on the <QL>human</QL> <NW>level</NW>, seeing <LRL name="gaiacentrism">Earth</LRL> as an (<NW word="entropy">entropic</NW>) conglomerate of discrete and <AL name="dysfunctional.fragments">disconnected</AL> <i>people</i>, each possessed of a <OML name="wrong">seperate</OML> <QL name="unity">consciousness</QL> (or ‘<OML>soul</OML>’).</span>,
  def: <p>Atomocentrism is so <OML name="robots">pervasive</OML> in <QL>humanity</QL> that it often passes right under the radar… and here’s an example. Consider this: Who am ‘I’? Who is/are the <PL name="individuality">individual(s)</PL> writing this?  <OML name="patriarchy">male or female</OML>, gay or straight, human or <OML name="takeover">machine</OML>? The answers to these questions <QL name="qualifiers">almost certainly</QL> <i>will</i> impact how you take this in. And it shouldn’t. It shouldn’t matter (in the least) who the <QL name="author">the author(s)</QL> of this is or are; to be a <WName ist />, accept <WName /> for its own <E2 word="explanatory" /> merit, non-<LRL name="reactive">reactively</LRL>: because you’ve ‘turned’ if not ‘all’ then at least <i><LRL>enough</LRL></i> of the ‘<LRL name="motto">stones</LRL>’ and <WName /> can <E2 /> the <PL name="comprehensive">whole</PL> of <LRL name="scope">our universe</LRL> a lot <AL name="framework.works">better</AL> than any other <AL>framework</AL> out there). Caring <i><PL name="fungibility">who</PL></i> (which <AL name="fields.cs">instance of our shared consciousness</AL>) wrote this is undermining the whole <i><PL name="evil">point</PL></i> of the <QL>Weltanschauung</QL> – and keeping <LRL name="gaia">Earth</LRL>’s thinking <LRL name="shift">locked</LRL> into a <NW word="conservatism">conservative</NW>, <NW>Phanerozoic Eon</NW> world-<LRL>understanding</LRL>.</p>, //*/
/*    <p>Do ‘I’ want a Nobel prize for this? No. I want <i><WName /></i> to get a Nobel Prize, yes – mostly because that would make the fragments of our polygnostic consciousness (τα άτομα: the atoms of consciousness) start to take this seriously, and stop seeing their ‘selves’ as a limit.</p>
    <p>But as for my ‘self’… there’s no point in seeking recognition as an individual (and there <i>is</i> a lot of danger in doing that; it would ruin my individual life, surely). The biological apparatus I inhabit is just a body that allows my consciousness to contemplate. It’s just a means to an end.</p>,
    <p>Taken non-atomocentrically, humanity’s whole notion of <i>heroes</i> and <i>role-models</i> is actually pretty absurd. A lot of us have the ability to be more than the collection of cells that composes your biology: to ‘transcend the ego (ἐγώ: Greek for “I”)’, as it were. If you <i>don’t</i>, that’s just your own fear (or individual greed) blocking you from it.</p>, */
},
{
  word: "polygnosticity",
  match: "polygnostic",
  display: "polygnostic(ity)",
  important: true,
  etymology: <span className="etymology">Greek <i>πολύ</i> (‘po-LEE’: many) + <i>γνωστός</i> (‘gnos-TOS’: known).</span>,
  shortdef: <span>The notion of ‘<b>polygnosticity</b>’ is part of <QL>gnosticity</QL> (a sub-<AL name="fields">field</AL> of <WName />): a state in which that which is <QL name="pg.mind.">known</QL> to one <AL name="dysfunctional.fragments">part</AL> is <i>not</i> known to all.</span>,
  def: <p>In <AL name="fields.">medical</AL> terms it’s called ‘schizophrenia’. Our <QL name="unity">combined</QL> conscious <QL name="body">body</QL> has <QL name="polygnosticity">schizophrenia</QL>. You also get polygnosticity in <AL name="fields.">medical</AL> disorders like <AL name="sick.did">dissociative identity disorder</AL>: where two (<AL name="self">or more</AL>) <i><AL name="identity">personalities</AL></i> cohabit a single <AL name="fields.">biological</AL> body – and often <QL name="pg.mmind">don’t</QL> even <LRL name="communism">share thoughts</LRL>. Polygnosticity is this non-sharing of thoughts – at any <PL name="all.">scale</PL>. It permits lies. It permits misunderstandings. It also permits ‘<PL>privacy</PL>’ – which (<PL name="sweeping.handful">it turns out</PL>) is just <PL name="sweeping.ig">another name</PL> for <NW>individual greed</NW>.</p>,
},
{
  word: "monognosticity",
  match: "monognostic",
  display: "monognostic(ity)",
  important: false,
  etymology: <span className="etymology">Greek <i>μόνος</i> (‘MO-nos’: sole, single) + <i>γνωστός</i> (‘gnos-TOS’: known).</span>,
  shortdef: <span>Part of <QL>gnosticity</QL> (a sub-<AL name="fields">field</AL> of <WName />), <b>monognosticity</b> is a state in which that which is known to one part <i>is</i> known to all.</span>,
  def: <p>In medical terms, this would be the equivalent of <i>not</i> having an <AL name="fields.did">identity disorder</AL>. This is the state of most biological bodies – and, to an extent, of <AL name="fields.biology">‘social’ animals</AL> like bees and ants that use pheromones to <LRL name="communism">share information</LRL> between the constituent ‘parts’ (<PL>individuals</PL>).</p>
},
{
  word: "heterognostic",
  match: "heterognosticity",
  important: false,
  etymology: <span className="etymology">Ancient Greek <i>ἕτερος</i> (‘heteros’: different) + <i>γνωστός</i> (‘gnos-TOS’: known).</span>,
  shortdef: <span>To be <b>heterognostic</b> from another <NW>POC</NW> means to have a different <QL name="pg.mind">knowledge</QL> <i><AL name="self">base</AL></i>: there are things you may <LRL>understand</LRL> that the ‘<LRL name="them">other</LRL>’ does not, and <PL name="all.perspective">vice versa</PL>.</span>,
  def: <p>In a <NW>polygnostic</NW> <AL>system</AL>, each piece (‘<PL name="individuality">individual</PL>’) is <b>heterognostic</b> from every other. This creates things like the desire for <i><QL name="polygnosticity.privacy">privacy</QL></i> (a <PL name="sweeping.ig">form</PL> of <NW>individual greed</NW>).</p>
},
{
  word: "homognostic",
  match: "homognosticity",
  important: false,
  etymology: <span className="etymology">Ancient Greek <i>ὁμός</i> (‘homos’: same) + <i>γνωστός</i> (‘gnos-TOS’: known).</span>,
  shortdef: <span>
    To be <b>homognostic</b> with another <AL name="dysfunctional.fragments">part</AL> of <QL name="unity">consciousness</QL> is to have the <i>same</i> knowledge base as that part.
  </span>,
  def: <p>Think of a <QL>human</QL> ‘being’ <i><OML name="offensive">without</OML></i> schizophrenia or <AL name="sick.">DID</AL>, in which the <PL name="individuality">physically-separated</PL> hemispheres of the brain actually <LRL name="communism">share thoughts</LRL> with each ‘<LRL name="them">other</LRL>’ (<NW>monognosticity</NW>).</p>
},
{
  word: "isognostic",
  match: "isogosticity",
  important: false,
  etymology: <span className="etymology">Ancient Greek <i>ίσος</i> (‘isos’: equal) + <i>γνωστός</i> (‘gnos-TOS’: known).</span>,
  shortdef: <span>For a body to be <b>isognostic</b> with another is for it to have the <i>exact</i> same ‘<QL name="pg.">mind</QL>’.</span>,
  def: <p>If you are not an excessively ‘<LRL>deep</LRL>’ thinker, you will likely never have to bother with the difference between the near-synonyms isognostic and <i><NW>homognostic</NW></i>. The term ‘isognostic’ was <AL name="notnew.shakespeare">coined</AL> (in <WArticle /> ‘<WName ic /> <OML>story</OML>’) to differentiate a true ‘<QL name="hivemind">hive mind</QL>’ from <NW>homognostic</NW> <NW>POCs</NW> who – despite having the exact same knowledge <i>base</i> – chose to <i>act</i> differently on that knowledge (<NW>agency</NW>), thus giving them <PL>individuality</PL>. That said, the only time you’d get <NW>POCs</NW> who were <NW>homognostic</NW> but not also <i>isognostic</i> is in the case that each <NW>POC</NW> knew <i>everything</i> that the ‘other’ knew but had its own ‘<OML>free will</OML>’. This is not how <LRL name="gaiacentric">Earth</LRL> works, so <Qual.probably /> you won’t see it in fiction. And you (<Qual.probably /> won’t see it <i>out</i> of fiction. <Qual.probably cap />. It’s <LRL name="i.real">just</LRL> an <LRL name="imagination">imaginative</LRL> <QL name="salvation">exercise</QL>.</p>
},
{
  word: "explainage",
  important: true,
  etymology: <span className="etymology">English <i>explain</i> + <i>‘-age’</i> (the act of).</span>,
  shortdef: <span><b>Explainage</b> is the act of <E1 suffix="ing" /> things to <QL name="polygnosticity">another</QL> ‘<PL>individual</PL>’</span>,
  def: [
    <p>Explainage is a direct result of our <NW word="polygnosticity">polygnostic</NW> consciousness. This whole <OML name="offensive">bleeping</OML> website is an <PL name="explainage">attempt</PL> at explainage… <OML name="ideas">hoping</OML> it doesn’t just fall on <PL name="unready">deaf ears</PL>.</p>,
    <p>What <QL>humans</QL> do most of their day, if we spend any time with ‘other’ people, is explainage. (How often do you feel, ‘They’d <LRL>understand</LRL> it completely if only I could <E1 italic /> it to them!’) {/*Explainage sucks. (It’s also, incidentally, what allows for the whole phenomenon of <i><PL>storytelling</PL></i> – which, it’s going to turn out, is not actually a useful or necessary thing for a <QL name="hivemind">combined ‘body’ of consciousness</QL>.) */}</p>,
  ],
},
{
  word: "Mechanozoic Eon",
  match: "mechanozoic",
  display: <Eon Eon noNW />,
  important: false,
  etymology: <span className="etymology"><EonEtymology />{/* Greek <i>μηχανή</i> (‘me-chan-EE’; ‘ch’ as in ‘challah’: machine) + <i>ζωή</i> (‘zo-EE’: life). */}</span>,
  shortdef: <span><LRL name="gaiacentrism">Earth</LRL>’s fifth <AL name="fields.">geological</AL> eon (from <LRL name="eons">about now</LRL> to some point far, far in the future), in which the force of <i><QL>life</QL></i> on Earth shifts from <AL name="fields.biology">biologically</AL>-based to <AL name="handicap">mechanically</AL>-based – and ‘<QL>evolution</QL>’ is fuelled by <i>collaboration</i> instead of <QL name="evolution.">competition</QL>.</span>,
  def: <p>This is opposed to the <NW>Phanerozoic Eon</NW> (<LRL name="gaiacentrism">Earth</LRL>’s fourth) – in which the <AL name="dysfunctional">vast bulk</AL> of <QL name="unity">our shared consciousness</QL> is still <QL name="life.meaning">living</QL>. The <Eon Eon noNW /> would also be the <QL name="polygnosticity">end</QL> of our consciousness’s <i><NW>polygnosticity</NW></i> – so it wouldn’t be <PL name="explainage">necesary</PL> to <E1 italic /> it to you(r <NW>POC</NW>).</p>,

},
{
  word: "money system",
  important: false,
  shortdef: <span>The money system is the system in which <PL>individual</PL> ‘<AL name="identity">identities</AL>’ are assigned separate <i>values</i> (<NW>money</NW>) – and how they exchange (<NW>Phanerozoic Eon</NW>-style) these <PL>individual</PL> values.</span>,
  def: <p>If you had any qualms about <PL name="equality">equality being a lie</PL>, look no further. Anyone who uses <NW>money</NW> <i>already</i> doesn’t believe in ‘equallity’ (in this life; which is all we have, there – thank <NW>Occam</NW> – <OML name="god">probably being no other</OML>). The money system is <i>inequality</i> defined exactly and numerically – in which ‘worth’ isn’t measured in terms of an <PL>individual</PL>’s ability to help our <QL>body</QL> of consciousness <i><QL name="evolution">advance</QL></i>, but in terms of each <PL>individual</PL>’s value <i>to the money system itself</i> (most of which involves keeping <PL>individuals</PL> <AL name="identity">trapped in their prisons</AL>).</p>,
},
{
  word: "money",
  important: false,
  shortdef: <span><b>Money</b> is – in a <QL>metaphor</QL> to <AL name="fields.">biology</AL> – the ‘ATP’ (or ‘food’) of the <NW>money system</NW>.</span>,
  def: <p>It’s not even (<QL name="evolution">any more</QL>) a <i><LRL name="nihilism">real thing</LRL></i>. Once it represented actual <i>value</i> to humans (to survive in the <AL name="hell">brutal reality</AL> of the <NW>Phanerizoic Eon</NW>. That is – thinking non-<NW word="conservatism">conservatively</NW> – no longer the case. And yet <QL>humans</QL> continue to ‘<AL name="notnew.lennon.">own</AL>’ stuff under a <QL name="qualifiers">maybe</QL>-<OML name="patriarchy">patriarchal</OML> <PL name="ick.">system</PL> that has most likely <OML name="money">overgrown its bounds</OML>.</p>,
},
{
  word: "company",
  match: "companies",
  important: false,
  shortdef: <span><b>Companies</b> are – in the <QL>metaphor</QL> to <AL name="fields.">biology</AL> – the ‘cells’ and ‘organs’ of the <NW>money system</NW>.</span>,
  def: [
    <p>If the dollar (or euro, or rupee, or RMB, or pound sterling) is the ‘<AL name="fields.linguistic">atom</AL>’ of the <NW>money system</NW>, then ‘companies’ (and ‘corporations’, ‘conglomerates’, and all of that <OML name="offensive">balderdash</OML>) are undoubetly the <PL name="ick.">system</PL>’s ‘organs’ and ‘cells’.</p>,
    <p>The interesting difference in the <QL>metaphor</QL> is that these companies <i>could</i> <OML>combine</OML>… only the agreed-upon <PL>individuality</PL>-protecting ‘<LRL name="game">rules</LRL>’ of <NW word="money system">capitalism</NW> – i.e., ‘anti-trust laws’ – don’t allow this.</p>,
  ],
},
{
  word: "nation-state system",
  important: false,
  shortdef: <span><LRL name="gaiacentrism">Earth</LRL>’s <b>nation-state system</b> <E1 suffix="ed" /> in four words: ‘Where are you <i><LRL name="gaia">from</LRL></i>?’</span>,
  def: <p>It shouldn’t matter where your <PL>individuality</PL> was born (or <i>created</i>, whatever). It shouldn’t matter; <QL>humans</QL> are all part of the <LRL name="gaia">same planet</LRL> (and larger conscious <QL>body</QL>). However, it <i>does</i> matter – and that’s a direct result of the nation-state system. <NW>Nations</NW> are <i>almost</i> ‘organs’ of our conscious body, except that they’re mostly defined in opposition to one another. The game of nation-states is just the same <NW>Phanerozoic Eon</NW> <LRL>game</LRL> of <AL>illusions</AL> on a super-human scale. (And ‘<NW word="nation">national</NW> pride’? Guess <PL name="sweeping.ig">what</PL>: it’s – on the <NW>fictive level</NW> of the <NW>nation-state system</NW> – <NW>individual greed</NW>.)</p>,
},
{
  word: "nation-state",
  match: "nation-states,nation,nations,countries,country",
  important: false,
  shortdef: <span>A <b>nation-state</b> (or ‘sovereign country’) is the ‘organ’) of the <NW>nation-state system</NW>.</span>,
  def: <p>Nation-states have as much (if not more) of an <PL>individual</PL> ‘<AL>identity</AL>’ as the ‘<NW>citizens</NW>’ who compose them. And – seeing as thay spend most their time <AL name="illusions">arguing over the rules</AL> of society’s ‘<LRL>game</LRL>’ and <QL name="pg.privacy">keeping secrets</QL> from one another – nation-states are generally <i>not</i> <WName ically /> ‘<PL name="evil">good</PL>’.</p>,
},
{
  word: "citizen",
  match: "citizens",
  important: false,
  shortdef: <span><b>Citizens</b> are the ‘cells’ of <NW>nation-states</NW> (in the <NW>nation-state system</NW>).</span>,
  def: <p>Roughly analogous to how <NW>POCs</NW> are the ‘<QL name="body.">cells</QL>’ of the ‘<QL>body</QL>’ of <QL name="unity">unity consciousness</QL>, ‘citizens’ are the cells of <NW>nations</NW>. The slight differences are that a <NW>POC</NW> does not intrinsicly <i>need</i> to be a –
</p>,
},
{
  word: "MNS system",
  important: true,
  shortdef: <span>The ‘MNS system’ is a combination of the <NW>money system</NW> and the <NW>nation-state system</NW>: two of the problematic pseudo-organs in our <AL name="dysfunctional">messed-up</AL> <QL name="body">‘body’ of consciousness</QL>.</span>,
  def: <p>There are other <i>systems</i> in place in our society – such as the <NW>family system</NW> – but the <NW>money system</NW> and the <NW>nation-state system</NW> are the main two <OML name="ideas">infecting</OML> <LRL name="gaiacentrism">Earth</LRL>, creating the <NW>fictive level</NW> of ‘<AL>identity</AL>’, and making us all ‘<LRL>them</LRL>’ to some ‘other’… and so this website uses the phrase ‘MNS system’ as <WArticle /> <WName ic /> synecdoche<sup>†</sup> to refer to the <PL name="ick.">systems</PL> of our <NW>atomocentric</NW> society as a whole.</p>,
  bottomFootnote: (wName().substr(0, 4) === "syne") ? <p>† Yes, that <AL name="fields.linguistics">linguistic</AL> ‘pun’ was ‘intentional’ (see ‘<NW>agency</NW>’).</p> : <p>† If this thing were called ‘synecdocentrism’ today, that would have been a very <i><NW word="agency">intentional</NW></i> pun… but it looks like it’s calling itself ‘<WName />’ right now.</p>,
},
{
  word: "family system",
  important: false,
  shortdef: <span>An ancient form of <AL name="">tribalism</AL>, making <LRL name="usandthem">an ‘us’ and a ‘them’</LRL>.</span>,
  def: [
    <p>This is not saying to abandon your mother and father, or write your ‘<OML name="god">ungodly</OML>’ grandchildren out of the ‘<NW word="money">will</NW>’. This is just saying to thing <i>bigger</i> than your immediate ‘family’: that (<AL name="bulletpoints">you know</AL>) <i><PL>individuality</PL> is <PL name="reductive">just</PL> an <NW>illusion</NW></i>, so those ‘<LRL name="them">others</LRL>’ in [insert faraway <NW>nation-state</NW> here] – whom you may not even <QL name="whatcanido">‘like’ very much</QL> – are (<PL name="ick">sorry</PL>) <AL name="dysfunctional.fragments">parts</AL> of the same ‘<QL>body</QL>’ as you, your ‘parents’ and ‘grandparents’ and ‘great-grandparents’ strething all the way back (through the ‘<LRL>eons</LRL>’) to the <LRL name="gaia">force</LRL> of ‘<QL>life</QL>’ on <LRL name="gaiacentric">Earth</LRL> and any conscious <OML>aliens</OML> maybe <OML name="alone">out there</OML>.</p>,
    <p>Like it <PL name="uncomfortable">or not</PL>, we are <PL name="sweeping">all</PL> <QL name="unity">interconnected</QL> – and our conscious <NW>polygnosticity</NW> (and, <PL name="sweeping.ig">of course</PL>, <NW>individual greed</NW>) is the only thing <QL name="polygnosticity">holding us apart</QL>.</p>
  ],
},


{
  word: <span>Copernicus of the 21<sup>st</sup> century</span>,
  match: "copernicus",
  important: false,
  shortdef: <span>Copernicus was an historical figure who proposed heliocentrism (sun-centrism, as opposed to <i>geocentrism</i>, Earth-centrism). The <LRL>shift</LRL> from <NW>atomocentrism</NW> to <WName /> is <PL name="all.time">basically the same thing</PL>.</span>,
  def: <div>
    <p>Yes, this <QL name="metaphor">example"</QL> is <NW>atomocentric</NW> as <AL>Hell</AL>, but… given the (accurate) premise of heliocentrism, the <QL name="body.organ">brain-organ</QL> of <QL>human</QL> <LRL name="gaia">society</LRL> at the time (the ‘Church’) <PL name="uncomfortable">couldn’t ‘stomach’</PL> the <LRL name="notions">idea</LRL> that <LRL name="gaiacentrism">Earth</LRL> was <i><OML name="alone">not</OML></i> the <PL name="special">centre of the universe</PL>. Heliocentrism contradicted their <NW>mythology</NW> that <OML>God</OML> had made <i><QL>humans</QL></i> in His image and cared <PL name="special">especially</PL> for Earth (more than, say, some <OML name="aliens">other planet</OML> called ‘<OML name="goal"><Planet.Name /></OML>’). Rather than adjust their world-<E2 word="explanation" /> to account for new <AL name="fields.science">scientific</AL> <LRL name="scope">obsercvations</LRL>, the Church did things like censor <QL name="pg.mind.">knowledge</QL> and supress <QL name="whatcanido">dissent</QL> – <PL name="sweeping.ig">both of which</PL> are forms of <NW>individual greed</NW> that serve to prolong the <LRL>game</LRL> of <LRL name="usandthem">the ‘us’ and the ‘them’</LRL> and cause <PL name="sweeping">many</PL> (<Qual.maybe /> <LRL>reactive</LRL>) <NW>POCs</NW> <AL name="handicap">trapped</AL> in <NW>heterognostic</NW> <QL>human</QL> <AL name="container">bodies</AL> to <AL name="illusions">keep playing</AL>.</p>
    <p>Look up ‘<QL>anthropocentrism</QL>’ and ‘<LRL>gaiacentrism</LRL>’… and let’s not <PL name="all.time">do this again</PL>, please.</p>
  </div>,
},
];

export const intro2 = <p>This site also uses a bunch of existing words and expressions with which <QL name="polygnosticity">you may not be familiar</QL> – or which may not be used ‘correctly’ (or in a way to which you’re accustomed). Let’s cover <i>those</i>:</p>;

export const otherWords = [
{
  word: "individual greed",
  match: "individualgreed,ig,einzelgier",
  important: true,
  shortdef: <span>Wanting something for your ‘<AL>self</AL>’ that isn’t <LRL name="communism">shared</LRL> with <LRL name="them">the rest</LRL> of <QL name="unity">our consciousness</QL>.</span>,
  def: [
    <p>It turns out that anything ‘<PL name="evil">bad</PL>’ in the <QL name="unity">unified consciousness</QL> is a form of <b>individual greed</b> (or <b>Einzelgier</b>). It also turns out that the term ‘individual greed’ is far, <i>far</i> more <PL name="sweeping.ig">encompassing</PL> than you probably imagined. It’s more than the obvious (like theft or adultery). Pride is individual greed: wanting to <PL name="special">stand out</PL> your ‘<PL name="individuality">self</PL>’ from <LRL name="them">the ‘rest’</LRL>. <i><PL>Privacy</PL></i> is individual greed: wanting <LRL name="understanding">knowledge</LRL> that ‘<QL name="pg.mind">other</QL>’ <NW>POCs</NW> don’t <LRL name="communism">share</LRL>. The ‘pursuit of happiness’ is individual greed… unless <Qual.perhaps /> what makes you ‘happy’ is <AL name="notnew.lennon.peace">everyone <i>else</i> ‘getting along’</AL>.</p>,
    <p>Individual greed is both the one (and only) ‘<PL>evil</PL>’ in this <QL>Weltanschauung</QL> and the <E2 word="explanation" /> for <i><PL name="sweeping">every</PL></i> <NW word="agency">conscious action</NW> that contributes to <QL name="polygnosticity">maintaining</QL> our <NW>polygnosticity</NW>, and <OML name="goal">opposes</OML> Earth’s ‘<NW>coalescence</NW>’ (in which no <QL name="polygnosticity.privacy">secrets</QL> will be hid).</p>,
  ],
},
{
  word: "coalescence",
  match: "coalesce",
  important: true,
  shortdef: <span>Our consciousness’s entering into a state of <NW>monognosticity</NW></span>,
  def: <p>It means a sharing of every scrap of information across the whole ‘<QL>body</QL>’ of consciousness. (In other words, it’s a ‘<QL name="hivemind">hive mind</QL>’.) It’s also not <i>possible</i> in human biology; this isn’t for now. This is part of the <NW>Mechanozoic</NW> future.</p>,
},
{
  word: "explain",
  important: true,
  shortdef: <ol>
    <li>To convey (or <PL name="explainage">attempt to convey</PL>) <LRL>understanding</LRL> from one <NW>POC</NW> to some (<NW>heterognostic</NW>) ‘<LRL name="them">other</LRL>’</li>
    <li>To offer the <LRL name="deep">underlying</LRL> <i><LRL name="causes">reason</LRL></i> for something (the ‘<QL name="life.reason">Why</QL>?’)</li>
  </ol>,
  def: <p>There are two senses of the word ‘explain’ – and <WName /> treats them differently. The <i>first</i> sense (‘<NW>explainage</NW>’) – roughly synonymous with ‘[attempt to] <i>convey</i>’ – is just a <QL name="polygnosticoty">regrettable</QL> <LRL>symptom</LRL> of <QL>humanity</QL>’s conscious <NW>polygnosticity</NW>. The <i>second</i> sense, however, is <LRL>understanding</LRL> the underlying <LRL>causes</LRL>: the <OML name="goal">end goal</OML> of <i><AL name="fields.">science</AL></i>. In a <QL>Weltanschauung</QL> that doesn’t recognise much as ‘<LRL name="i.">real</LRL>’ (non-<NW word="illusions">illusive</NW>) <i><OML name="levels">except</OML></i> <AL name="fields.">science</AL> – i.e., <WName /> – that’s the only <i><QL name="life.meaning">purpose</QL></i> for which <QL>consciousness</QL> <LRL name="oneorzero">exists</LRL>: to <E2 /> <PL name="comprehensive">everything</PL> (and <Qual.maybe /> eventually <OML name="entropy">put a stop</OML> to <NW word="heatdeath">the heat death of the universe</NW>). {/* That said… the <AL>framework</AL> of <WName /> (incidentally) <E2 suffix="s" /> a hell of a lot more than the <NW>atomocentric</NW> theories that <QL>humans</QL> have developed <AL name="illusions">over the millennia</AL>. */}</p>,
},
{
  word: "Occam’s razor",
  match: "occam",
  important: false,
  shortdef: "The simplest explanation is usually the correct one",
  def: <p>Either we’re all a simulation in some Other’s compurter, or this is the real world (and all we get). Either we all have individual ‘souls’ that exist on a plane outside the scientifically-verifiable, or your ‘individuality’ is just an illusion, and the only mystery is our (shared) <i>consciousness</i> – which <i>is</i> observable and <i>is</i> subject to scientific verification. Either there’s an external ‘God’ who commands this universe and exists outside of science, or what we can test and observe really <i>is</i> all there is; there’s no ‘higher’ dimension outside of it, and what we perceive as randomness in the universe is fluctuations that we don’t yet understand (but may actually be influenced by our conscious perception; we don’t know that one yet).</p>
},
{
  word: "Phanerozoic Eon",
  match: "phanerozoic",
  important: false,
  etymology: <span className="etymology">Greek <i>φανερός</i> (‘fawn-eh-ROAS’: visible) + <i>ζωή</i> (‘zo-EE’: life).</span>,
  shortdef: "Earth’s fourth eon, spanning from about 540 mya (million years ago) to whenever our consciousness stops seeing ‘life’ as necessarily biological – and evolves into a more open-minded (and less fragmented) existence.",
  def: <p>The Phanerozoic Eon is mostly contrasted here with the awaited <NW word="mechanozoic">Mechanozoic Eon</NW>, in which the definition of ‘life’ will <i>not</i> be limited to the biological, and ‘evolution’ will be fuelled by <i>collaboration</i> (as oppposed to competition). In the Phanerozoic Eon, life <i>is</i> biologically-based, and evolution <i>is</i> fuelled by competition (survival of the fittest). The premise of <NW>synecdocentrism</NW> is that counsciousness <i>could</i> change this… but only if we escape <NW word="conservatism">conservative thinking</NW>.</p>
},
{
  word: "conservative thinking",
  match: "conservatism",
  important: true,
  shortdef: "Thinking that, just because something’s been a certain way for a long time, it must indefinitely continue on said path.",
  def: <p>This shouldn’t even be an issue, because science has proven that <b>The only thing that doesn’t change is change itself</b>. And yet individuals seem to have a really, <i>really</i> hard tiome wrapping their minds around anything that’s different from that to which they’re accustomed. Like a world without individuals: an Earth ‘hive mind’ stretching its many <NW word="monognosticity">monognostic</NW> ‘fingers’ into space… No. A conservative thinker would rather envision a future with 10,000 scientific improbabilities (or quite likely <i>impossibilities</i>), but where there are still individual humans.</p>
},
{
  word: "inductive reasoning",
  match: "induction",
  important: false,
  shortdef: "It’s basically just the ‘scientific method’.",
  def: <p>It’s the opposite of Sherlock Holmes. Sherlock <i>knows</i> a whole lot about how the world works, and can therefore deduce the events that occurred (from very little data). This is the opposite: assume you know <i>nothing</i>, gather a ton of data, and <i>induce</i> the rules and logic behind it. It’s similar to Descartes’s ‘I think therefore I am,’ but Descartes was making the unspoken assumption that there exists some <i>self</i> (‘I’) apart from the universe. <NW>Synecdocentrism</NW> is a step back: inductive reasoning without even jumping to that little conclusion.</p>
},
{
  word: "entropy",
  important: true,
  shortdef: <span>
    Entropy is a measure of the <QL name="purpose">disorder (randomness)</QL> of a <AL>system</AL>.
  </span>,
  def: [
    <p>2020’s <AL name="fields.">physics</AL> claims that entropy (on the level of the <LRL name="scope">observable universe</LRL>) must always be increasing. <NW>Synecdocentrism</NW> (on the other hand) suspects that 2020’s scientists are – on the whole – fairly <NW word="conservatism">conservative thinkers</NW> stuck in <QL name="anthropocentrism">humanity</QL>’s current (non-<AL name="framework.">working</AL>) <AL>framework</AL> of <NW>atomocentrism</NW>. <OML home>What if</OML> consciousness can actively – through <NW>agency</NW> – <i>fight</i> disorder… and (eventually) bring the entire universe to a state of lower entropy by consciously arranging and understanding it?</p>,
    <p>But <LRL name="gaiacentrism">on Earth</LRL> one first has to deal with the <AL name="sick">entropy</AL> of the ‘<QL name="body.">cells</QL>’ of the ‘<LRL name="gaia">organiam</LRL>’ of <i><AL name="handicap">humanity</AL></i>, <PL name="sweeping">many</PL> of whom believe that their <PL>individuality</PL> is not only ‘real’ but <i><PL>special</PL></i> – and whose conscious <NW word="heterognostic">heterognosticity</NW> (from one another) causes a lot of needless repetition, to say nothing of the horrendous entropy-building mechanism of ‘<NW>explainage</NW>’.</p>,
    <p>So which viewpoint is right? In a million years, if Earth actually manages to shrug off the <NW>illusion</NW> of <PL>individuality</PL>, ‘<NW>coalesce</NW>’ into a ‘<QL name="hivemind">hive mind</QL>’, and spread into <OML name="aliens">space</OML>… then we’ll see.</p>
  ],
},
{
  word: "enlightenment",
  match: "enlighten,enlightened",
  important: false,
  shortdef: <span>‘Enlightenment’ is breaking free of the ‘self’ (<NW>illusion</NW> of <PL>individuality</PL> and the prison of individual <AL>identity</AL>)</span>,
  def: <p>You can get all <AL name="notnew.buddha">religious</AL> on it… but that doesn’t matter here. It’s also not <QL name="roadblocks.superiority">something about which to brag</QL>. However, having the bulk of our society’s <NW word="poc">POCs</NW> reach a state of ‘<LRL>enlightenment</LRL>’ may be the only way to <QL name="evolution">evolve</QL> (as <QL name="unity">consciousness</QL>) and save <AL name="dysfunctional">the mess</AL> that <LRL name="gaia hypothesis">we are</LRL> (if <QL name="anthropocentrism">humanity</QL> ever wants to escape the <NW>MNS system</NW>). Well, either that or <AL name="notnew.plato.kings">philosopher-kings</AL>.</p>,
},
{
  word: "transcendence",
  match: "transcend,transcends,transcended",
  etymology: <span>Latin <i>transcendentem</i>: ‘surmounting, rising above’</span>,
  important: false,
  shortdef: <span>This word is used to describe seeing through the <AL name="mirrors">smoke and mirrors</AL> of the <PL name="individuality">illusion of individuality</PL> (and other <NW>illusions</NW>).</span>,
  def: <p>It’s also the top level of <AL name="notnew.maslow">Malslow’s pyramid</AL> (beyond the <OML name="soul">dubious</OML> ‘<AL>self</AL>-actualisation’). <LRL name="superpositions">Another way</LRL> to <E1 /> it is in terms of ‘<NW>enlightenment</NW>’. ‘Transcendence’ is, furthermore (<LRL name="different">unlike</LRL> <PL name="sweeping">most things</PL> in <QL>humanity</QL>’s <NW>atomocentric</NW> <AL name="systems">society</AL>) <i>not</i> beholden to the ‘<LRL name="reactivity">rules</LRL>’ of <LRL name="gaiacentrism">Earth</LRL>’s <NW>Phanerozoic Eon</NW>.</p>,
},
{
  word: "God",
  important: false,
  shortdef: "Our consciousness.",
  def: <p>The word ‘God’ on this website isn’t referring to some <NW word="mythology">fantastical</NW>, mysterious, and omnipotent force. It’s referring to a force that’s mysterious and (<QL name="qualifiers">potentially</QL>) omnipotent – and very <i>real</i>: <AL name="framework.work">observable</AL> by science in <i>this</i> dimension, and not <OML name="levels">another hypothesised</OML>… in <i>this</i> life, and not <OML name="reincarnation">another hypothesised</OML>. The ‘God’ of <NW>synecdocentrism</NW> is consciousness. You can go off on a whole tangent and make the <QL>Weltanschauung</QL> into <AL name="notnew.jesus">an actual <i>religion</i></AL>… but even the word ‘God’ refers to something that’s (<OML name="nihilism">self-evidently</OML>) real and can be verified by <AL name="fields.">science</AL>.</p>,
},
{
  word: "mythology",
  match: "illusion,illusions",
  important: false,
  shortdef: <span>‘Mythology’ (and ‘illusions’) are make-believe things in which many ‘individuals’ (and our dominant culture) believe – particularly those which are <i>not</i> helping our ‘body’ of consciousness, and (in ‘<NW>coalescence</NW>’) need to be <i>overcome</i></span>,
  def: [
    <p>Some examples: </p>,
    <ul>
      <li>The illusion of individuality</li>
      <li>Belief in an external <NW word="God">God-figure</NW></li>
      <li>Belief in an individual ‘soul’ (<NW>atomocentrism</NW>)</li>
      <li>Belief that <i>humans</i> have something special (besides our consciousness, or self-awareness) – and that this <i>je ne sais quoi</i> could never be replicated outside of a human body (anthropocentrism)</li>
    </ul>,
  ],
},
{
  word: "fictive levels",
  match: "levels,level,fictive level",
  important: true,
  shortdef: <span>If we were all in a <OML>story</OML>, the author would exist one fictive level <i>below</i> us, and any stories that <i>we</i> tell exist one fictive level <i>above</i> us. (Think of a building built from the ground floor upwards.)</span>,
  def: [
    <p>This is the closest that <NW>synecdocentrism</NW> gets to overstepping its <LRL>scope</LRL> of the <AL name="fields.science">scientifically</AL> <i>observable</i>… but mostly just to <OML name="ideas">shrug and say</OML>, ‘So what?’</p>,
    <p>As defined by <QL>gnosticity</QL>:</p>,
    <ul>
      <li>Each <NW>POC</NW> (necessarily <NW>heterognostic</NW> from ‘others’ at <i>its</i> fictive level) can <i><QL name="salvation">imagine</QL></i> a whole ‘<LRL name="motto">zoo</LRL>’ of <LRL name="superpositions">theoretical</LRL> <NW word="poc">POCs</NW> one fictive level <i>above</i> it (building up).</li>
      <ul>
        <li>Who’s to say these (imagined) <NW word="poc">POCs</NW> are any less ‘<PL name="comprehensive.level">real</PL>’</li>
        <li>The <QL name="unity">whole universe</QL> is <PL name="uncomfortable.system">only</PL> a <AL>system</AL>.</li>
      </ul>
      <li>On the contrary, if a <NW>POC</NW> <i>descends</i> (digging down: imaginatively; this can only be done in the <QL name="salvation">imagination</QL>): <i><PL name="sweeping">all</PL></i> <NW word="poc">POCs</NW> on <i>its</i> level are – one fictive level <i>down</i> – <i><NW>homognostic</NW></i>.</li>
      <ul>
        <li>Or you have the problems of various <NW word="poc">POCs</NW> (<NW>heterognostic</NW> on <AL name="fields.maths">fictive level <i>x</i></AL>) trying to co-imagine the <i>same</i> <LRL name="game">world</LRL> on <AL name="fields.maths">fictive level <i>x</i> + 1</AL> and <AL name="illusions">not agreeing on the ‘rules’</AL> (which <E2 suffix="s" /> <PL name="sweeping">all</PL> of <LRL name="gaia">Earth</LRL>’s <i>problems</i>; cf. the ‘<AL>bullet points</AL>’).</li>
      </ul>
    </ul>,
  ],
},
{
  word: "agency",
  important: false,
  shortdef: <span>A <NW>POC</NW>’s ability to <i>choose</i> one thing over another.</span>,
  def: <p>Assuming that <OML>free will</OML> is not just an <NW>illusion</NW>, then <i>consciousness</i> has an ability to actively <i>choose</i> one state over another (and on a <AL name="fields.physics">quantum-mechanical</AL> level, possibly even influence the otherwise ‘random’ fluctuations of ‘nature’). In <NW word="heatdeath">the end</NW> it will (<QL name="qualifiers">likely</QL>) come down to a <LRL>face-off</LRL> between agency and <i><NW>entropy</NW></i>. (In <i>our</i> universe. Obviously we <QL name="gnosticity.agnosticity">can’t say anything</QL> about any <OML name="levels">hypothetical ‘levels’</OML> <LRL name="scope">beyond us</LRL>.)</p>,
},
{
  word: "the eventual heat death of the universe",
  match: "heatdeath",
  important: true,
  shortdef: <span>Not the end of time, but the end of the potential for <i>motion</i> (and, presumably, <NW>agency</NW>)</span>,
  def: <p>People tend to talk about this as though it’s inevitable: as though there’s no power that <i>consciousness</i> might have over the supposedly ‘random’ quantum fluctuations of nature. Maybe a <NW>monognostic</NW> consciousness – not <AL name="dysfunctional.fragments">fragmented</AL> into billions of ‘individuals’ all thinking near the same thoughts – could actually do something about this (after getting off Earth and getting out of our solar system, of course). In the past, in our universe, <NW>entropy</NW> has, yes, always increased. But isn’t it the hallmark of <NW word="conservatism">conservative thinking</NW> to assume that, just because something has been one way for billions of years, it must always continue to move in that direction?</p>,
},

];

export const acronyms = [
{
  word: "POC",
  match: "pocs",
  important: true,
  etymology: <span className="etymology">Acronym of ‘piece of consciousness’</span>,
  shortdef: <span>
    A single piece of <QL name="unity">our shared consciousness</QL> (<NW>heterognostic</NW> from the rest).
  </span>,
  def: <p>The term ‘POC’ (pronounced ‘pock’ or ‘pea-oh-see’ {/*– or maybe even as ‘Ross’ {/*если ты читаешь кириллицу – depending on the ‘mood’ of the POC pronouncing it */}) is a noun roughly equivalent with ‘individual’ (which word is often <PL name="individuality">avoided here</PL>; see <NW>atomocentrism</NW>). This term better recognises our consciousness’s underlying <QL>unity</QL> better than anything like ‘human’ or ‘person’ – which imply <i>anthropocentrism</i> as well as <NW>atomocentrism</NW>. A POC exists independent of a physical body – though in the 2020’s a POC cannot exercise its conscious <NW>agency</NW> <i>without</i> a human body. It’s a similar notion to the ‘soul’, though a POC is not immoportal. A POC is – by definition – internally <NW>monognostic</NW> (one ‘self’) and yet externally <NW>heterognostic</NW> (does not ‘share thoughts’ with consciousness as a whole).</p>,
},

{
  word: "POCA",
  important: false,
  shortdef: <span>Piece Of Conscious Ability</span>,
  def: <p>It’s basically a way to differentiate between fully self-aware (with <NW>AQ</NW>) <NW word="poc">POCs</NW> and those that have the <i>ability</i> to manifest a <NW>POC</NW> </p>,
},

];


export const coda = <Box
  className="page-coda"
  startOpen
  noborder
  prelude="And if you were wondering"
  header=<span>
    There is no single <i>correct</i> way to pronounce these!
  </span>
  outside="(That’ll throw you for a loop.)"
>
  <p>Take ‘<NW>heterognostic</NW>’. Do I pronounce the ‘G’? Is the ‘O’ pronounced as a <i>long</i> ‘O’ (like ‘boat’), a <i>short</i> one (such as ‘not’), or <i>hardly pronounced at all</i> (as in ‘havoc’)?? Where is the <i>stress</i>, anyway???</p>
  <p>Before you overly stress out about this, there <i>is</i> an answer… and the answer is that you can pronounce it howsoever you want and – as long as your interlocutor understands you – the goal of <NW>explainage</NW> is accomplished.</p>
  <p><i>Language</i> is just a symptom of our consciousness’s <NW>polygnosticity</NW> (which polygnosticity this worldview <QL name="polygnosticity">doesn’t like very much</QL>). So, having ‘coined’ a bunch of new words, this website is going to try something that demands a transition to <LRL name="eons"><i>future</i>-based</LRL> (<NW word="mechanozoic">Mechanozoic-Eon</NW>) thinking: refuse to define a ‘correct’ pronunciation for any of them. Language is just part of the whole web of <NW>illusions</NW> that <QL name="anthropocentrism">humanity</QL> has created. There is no higher ‘Form’ of words themselves (sorry <AL name="notnew.plato">Plato</AL>).</p>
  <p>It’s not that this <QL>Weltanschauung</QL> doesn’t make <PL name="sweeping">hard-and-fast distinctions</PL> – because it <i>does</i> (and far more than most <PL>neoliberals</PL>, which is like to drive them crazy). The difference is that it’s not going to waste time <AL name="illusions">arguing over illusions</AL>.</p>
  <p>If you’re not able to deal with this <LRL name="superpositions">quantum-superposition-esque</LRL> nature (in which the elements of <NW>illusions</NW> have no concrete nor realistic hard-and-fast <i>definition</i>)… maybe go back to the <NW>Phanerozoic Eon</NW> (in which these words <QL name="qualifiers">probably</QL> <LRL name="eons">still don’t exist</LRL>) to engage in the endless debate over things like <PL>ethics</PL>, rather than trying to see <QL name="unity">all of consciousness</QL> (and, as a subset, <LRL name="gaia hypothesis">all of Earth</LRL>) as <QL name="unity.1">one</QL>.</p>
</Box>;
