import React from 'react';
import Box from './Box';
import './Opening.css';

function Opening() {
  const titlePrelude = <span>
    And I’m sorry if this offends your <i>individuality</i>, but…
  </span>;
  const titleHeader = "It’s time for a (radical) shift in perspective:";
  const titleOutside = <span style={{fontSize: "16pt"}}>
    From <span className="badidea">‘you’</span> and <span className="badidea">‘I’</span>…<br />
    to just <span className="goodidea">‘us’</span>.
  </span>;
  return (
    <div className="maintext">
      <Box
        prelude={titlePrelude}
        header={titleHeader}
        outside={titleOutside}
        expandText="Enter"
        redirect={null /* "/menu" */}
      />
      <h3>This is SO BAD and misreprents the Weltanschauung. Taking it down until whoever is ‘in charge’ of this stupid thing even gets its act together.</h3>
    </div>
  );
}

export default Opening;
